import { ReactElement } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import logo from 'assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { UserState } from 'redux/slices/user'
import { useAppSelector, useAppDispatch } from "redux/hooks"
import { setAuthenticated } from "redux/slices/user"
import useLocalStorage from 'hooks/useLocalStorage'

import './navbar.css'

export type NavProps = {
  breadcrumbs: ReactElement
}

const Navbar = (props: NavProps) => {
  const navigate = useNavigate() 
  const currentUser: UserState = useAppSelector((state) => state.user)
  const [, setValue] = useLocalStorage("id_token", "")
  const dispatch = useAppDispatch()

  return (
    <div id="navbar" className="container" >

      <img alt="MyInfra" src={logo} />

      {
        props.breadcrumbs
      }

      {
        currentUser && currentUser.isAuthenticated &&

        <Dropdown className="navbar-end" align="end">
          <Dropdown.Toggle variant="link">
            {currentUser.displayName}
          </Dropdown.Toggle>
  
          <Dropdown.Menu className="shadow bg-body rounded dropdown-menu-end">
            
            <Dropdown.Item onClick={() => navigate('/users')}>Users</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/analytics')}>Analytics</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/lms')}>LMS</Dropdown.Item>
            <Dropdown.Item onClick={() => {
              setValue(""); 
              localStorage.removeItem('id_token')
              document.cookie = 'client=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              dispatch(setAuthenticated(false)); 
              navigate('/login')}
            }>Sign Out</Dropdown.Item>
  
          </Dropdown.Menu>
        </Dropdown>
      }
    
    </div>
  )
}

export default  Navbar
