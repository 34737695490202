import { useAppSelector } from "redux/hooks"
import { Link as RouterLink } from "react-router-dom"
import Login from "pages/login"
import Copyright from "components/Copyright"
import "./unknown.css"

const Unknown: React.FC = (props: any) => {
  const currentUser = useAppSelector((state) => state.user)
  const {isAuthenticated} = currentUser

  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <>
      <div className="unknown-container">
        <br />
        <h1>Page not found (404)</h1> 
        <p>Dear {currentUser.displayName}, we are unable to find requested page.</p>
        <br />
        <RouterLink to="/users">Go back to User Administration</RouterLink>
        <br />
        <br />
      </div>
      <Copyright /> 
    </>
  )
}

export default Unknown