import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export interface SkeletonProps {
  children: JSX.Element,
  isLoading: boolean,
  width?: string,
  height?: string,
  variant?: 'circle',
  containerClassName?: string,
  style?: any
}

/**
 * 
  // Method 1 - using standard function
  const renderSkeleton = (el: ReactElement, isLoading: boolean) => {
    return isLoading ? 
    (
      <Skeleton
        width="100%"
        height="38px"
      />
    ) : (
      el
    )
  }

  Invoking Method 1:
  renderSkeleton(<div />, reader.isLoading)


  // Method 2 - using standard function
  const SkeletonWrapper = (props: SkeletonProps): JSX.Element => { 
    return props.isLoading ? 
    (
      <Skeleton
        width="100%"
        height="38px"
      />
    ) : (
      props.children
    )
  }

  // Method 3 - using functional components
  const SkeletonWrapper: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    return reader.isLoading ? 
    (
      <Skeleton
        width="100%"
        height="38px"
      />
    ) : (
      props.children
    )
  }

  Invoking Method2 and Method3 (both samples will work with both methods)

  <SkeletonWrapper children={
    <input type="text" value={provider} onChange={(e) => handleChange(e, "provider")}
      className="form-control" placeholder="Provider" />
  }/>

  OR

  <SkeletonWrapper isLoading={reader.isLoading}>
    <input autoFocus 
      ref={refName}
      type="text" value={displayName} onChange={(e) => handleChange(e, "displayName")}
      className="form-control" placeholder="Display name" />
  </SkeletonWrapper>

*/

const SkeletonWrapper = (props: SkeletonProps): JSX.Element => {
  const {
    children,
    isLoading,
    width,
    height,
    style,
    variant,
    containerClassName
  } = props

  return isLoading ? (
    <Skeleton 
      width={width || "100%"} 
      height={height || "38px"} 
      {...(containerClassName ? {containerClassName} : {})}
      {...(style ? {style} : {})}
      {...(variant ? {circle: true} : {})}
    />
  ) : (
    children
  )
}

export default SkeletonWrapper