import Pagination from "react-bootstrap/Pagination"
import "./CustomPagination.css"

export interface PaginationProps {
  current: number,
  recordCount: number,
  pageSize: number,
  pageWindow: number,
  onPageChange: (arg: number) => void
}

const CustomPagination = (props: PaginationProps): JSX.Element => {

  const {current, recordCount, pageSize, pageWindow, onPageChange} = props
  const pageCount = Math.ceil(recordCount / pageSize)

  const isFirst = current === 1
  const isLast = current === pageCount
  const halfWindow = Math.floor(pageWindow/2)

  let start = Math.max(current - halfWindow, 1)
  let end = Math.min(start + pageWindow - 1, pageCount)

  if (pageCount >= pageWindow && end === pageCount) {
    start = pageCount - (pageWindow - 1)
  }



  let items = [];
  for (let i = start; i <= end; i++) {
    items.push(
      <Pagination.Item key={i} active={i === current} onClick={() => onPageChange(i)}>
        {i}
      </Pagination.Item>
    )
  }

  return (
      <div className="customPagination">
      <Pagination>
        <Pagination.First disabled={isFirst} onClick={()=>onPageChange(1)}/>
        <Pagination.Prev disabled={isFirst} onClick={()=>onPageChange(current-1)}/>
        {
          items
        }
        <Pagination.Next disabled={isLast} onClick={()=>onPageChange(current+1)}/>
        <Pagination.Last disabled={isLast} onClick={()=>onPageChange(pageCount)}/>
      </Pagination>
    </div>
  )
}

export default CustomPagination