
import { useNavigate } from "react-router-dom"
import {UserData} from "pages/users/Users"
import CustomActionCell from "components/table/CustomActionCell"
import Spin from "components/Spin"

interface CustomBodyProps {
  rows?: UserData [],
  isLoading: boolean,
  children: JSX.Element,
  pageNumber: number,
  pageSize: number
}

const CustomTableBody = (props: CustomBodyProps): JSX.Element => {

  const navigate = useNavigate() 
  const {rows, isLoading, children, pageNumber, pageSize} = props

  if (isLoading) {
    return (
      <tbody>
        <tr title="loader">
          <td colSpan={5} className="text-center" style={{color: '#337ab7'}}>
            <Spin />
            <br/>
            Loading
          </td>
        </tr>
      </tbody>
    )
  }

  const start = (pageNumber-1) * pageSize
  const end = pageNumber * pageSize

  if (rows?.length) {
    return (
      <tbody>
        {
          rows?.slice(start, end).map((row: any, idx: number) => {
            const id = start+idx+1
            return (
              <tr key={id}>
                <td>{id}.</td>
                <td style={{fontWeight: 'bold', color: 'var(--bs-primary)', cursor: 'pointer' }} onClick={() => {navigate(`/users/${row.id}`)}}>{row.displayName}</td>
                <td>{row.email}</td>
                <td>{row.role}</td>
                <td><CustomActionCell id={row.id}/></td>
              </tr>
            )
          })
        }
      </tbody>
    )
  }

  return <tbody>{children}</tbody>

}

export default CustomTableBody