import { Route, BrowserRouter, Routes } from "react-router-dom"
import Analytics from "pages/analytics"
import Lms from "pages/lms"
import Users from "pages/users"
import User from "pages/user"
import Login from "pages/login"
import Unknown from "pages/unknown"
import Navbar from "components/navbar"
import Breadcrumbs from "components/breadcrumbs"
import { ModalProvider } from "components/modal/ModalContext"
import useLocalStorage from "hooks/useLocalStorage"
import { useAppDispatch } from "redux/hooks"
import { setUser } from "redux/slices/user"
import axios from "axios"

import "App.css"
import "assets/bootstrap/style.css"

const getUserFromToken = (token: string | null) => {
  if (!token)
    return "";

  const t = token.split(".");

  if (t.length !== 3)
    return "";

  const b64Token = t[1];
  const obj = JSON.parse(atob(b64Token.replace(/_/g, '/').replace(/-/g, '+')));
  return obj
}

const App = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [storedValue, ] = useLocalStorage("id_token", "")

  const payload = getUserFromToken(storedValue)

  if (payload) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${storedValue}`
    dispatch(setUser({id: payload._id, isAuthenticated: true, displayName: payload.displayName}))
  }

  if (!storedValue) {
    return <Login />
  }

  return (
    <>
      <BrowserRouter>

        <Navbar breadcrumbs={<Breadcrumbs/>}/>
        
          <div className= "container modal_parent">
            <ModalProvider>
              <Routes>
                <Route path="/" element={<Users />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/lms" element={<Lms />} />
                <Route path="/users" element={<Users role="public"/>} />
                <Route path="/login" element={<Login />} />
                <Route path="/users/:id" element={<User />} />
                <Route path="/*" element={<Unknown />} />
              </Routes>
            </ModalProvider>
          </div>
        
      </BrowserRouter>

    </>
  )
}

export default App
