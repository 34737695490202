const Analytics = (): JSX.Element => {
  return (
    <div id="home" className="container" style={{height: "1280px"}}>
      <iframe title="analytics" style={{border:"1px solid #e7e7e7"}}
        frameBorder="0" scrolling="no" seamless={true}
        src="/analytics/goto/d500f63e37fad46630126b4ca32b3733?embed=true" height="1280px" width="100%">
      </iframe>
    </div>
  )
}

export default Analytics