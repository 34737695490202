import { ReactElement, useState } from "react"

interface PromiseInfo {
  resolve: (value?: any) => any,
  reject: (reason?: any) => void
}

export interface ModalProps {
  isModalOn: boolean,
  isFadingOut: boolean,
  showModal: (arg: ReactElement) => any,
  hideModal: (response?: 'yes' | 'no') => void,
  content: ReactElement
}

export const DefaultModalProps = {
  isModalOn: false,
  isFadingOut: false,
  showModal: (arg: ReactElement) => new Promise((res, rej)=>res("no")),
  hideModal: (response?: 'yes' | 'no') => {},
  content: {} as ReactElement
}

const useModal = (): ModalProps => {
  const [isModalOn, setModalOn] = useState(false)
  const [isFadingOut, setFadingOut] = useState(false)
  const [content, setContent] = useState<ReactElement>(DefaultModalProps.content)
  const [promiseInfo, setPromiseInfo] = useState<PromiseInfo>({resolve: (str: string)=> str, reject: ()=>{}})

  const showModal = async (label: ReactElement): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      document.body.style.overflow = 'hidden';
      setModalOn(true)
      setFadingOut(false)
      setContent(label)
      setPromiseInfo({resolve, reject})
    })
  }

  const hideModal = (response?: 'yes' | 'no') => {
    document.body.style.overflow = 'scroll';
    setFadingOut(true)
    
    setTimeout(() => setModalOn(false), /*wait for anymation*/ 1000);
    promiseInfo.resolve(response)
  }

  return {
    isModalOn,
    isFadingOut,
    showModal, 
    hideModal,
    content
  }
}

export default useModal