import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
export interface UserState {
  id: string | null,
  displayName: string | null,
  isAuthenticated: boolean
}

// Define the initial state using that type
const initialState: UserState = {
  id: null,
  displayName: null,
  isAuthenticated: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      Object.assign(state, action.payload)
    },
    setDisplayName: (state, action: PayloadAction<UserState>) => {
      state.displayName = action.payload.displayName
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },

  },
})

export const { setDisplayName, setAuthenticated, setUser } = userSlice.actions

export default userSlice.reducer