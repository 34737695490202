import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { Provider } from "react-redux"
import { store } from "redux/store"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

const getUrlParameterByName = (field: string) => {
  const url = window.location.href;
  const name = field.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const b64 = getUrlParameterByName("user")

if (b64) {
  const str = atob(b64.replace(/_/g, '/').replace(/-/g, '+'))
  const payload = JSON.parse(str)
  window.localStorage.setItem("id_token", JSON.stringify(payload.id_token))
  document.cookie = "client=" + payload.id_token + "; expires=Thu, 01 Jan 2100 00:00:01 GMT;";
  window.location.replace("/")
}
else {

  /*
  //StrictMode renders components twice (on dev but not production) in order to detect any problems 

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )
*/

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
}

/*
  //StrictMode renders components twice (on dev but not production) in order to detect any problems 

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )
*/
