import React, { createContext, ReactNode } from "react"
import useModal, { DefaultModalProps } from "./useModal"
import SubModal from "./Modal"

type Props = {
  children?: ReactNode
}

const ModalContext = createContext(DefaultModalProps)
const {Provider} = ModalContext

const ModalProvider = (props: Props) => {
  const { isModalOn, isFadingOut, showModal, hideModal, content} = useModal()
  
  return (
    <Provider value={{isModalOn, isFadingOut, showModal, hideModal, content}}>
      <SubModal />
      {props.children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }