import { useState, useCallback } from "react"
import axios, { AxiosRequestConfig, Method } from "axios"


export interface ApiClient {
  get: (url: string, options?: AxiosRequestConfig) => Promise<number>
  post: (url: string, payload?: any, options?: AxiosRequestConfig) => Promise<number>
  put: (url: string, payload?: any, options?: AxiosRequestConfig) => Promise<number>
  delete: (url: string, options?: AxiosRequestConfig) => Promise<number>
  data: any
  error: string
  isLoading: boolean
}

export const useFetch = (): ApiClient => {
  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const fetch = useCallback( async (
    url: string, 
    method: Method, 
    payload?: any,
    options?: AxiosRequestConfig
    ): Promise<number> => {

    setLoading(true)

    let response: any = null

    try {

      const config: AxiosRequestConfig = {
        method: method,
        url: url,
        ...options,
        ...payload && {data: payload}
      }

      response = await axios(config)
      setData(response.data)
    } catch (ex) {
      setError((ex as Error).message)
    } finally {
      setTimeout(() => setLoading(false), 400);
      return response?.status || 500
    }

  }, [])

  return {
    data,
    error,
    isLoading,
    get: useCallback((url: string, options?: AxiosRequestConfig) => fetch(url, 'get', null, options), [fetch]),
    post: useCallback((url: string, payload: any, options?: AxiosRequestConfig) => fetch(url, 'post', payload, options), [fetch]),
    put: useCallback((url: string, payload: any, options?: AxiosRequestConfig) => fetch(url, 'put', payload, options), [fetch]),
    delete: useCallback((url: string, options?: AxiosRequestConfig) => fetch(url, 'delete', null, options), [fetch])
  }

}

