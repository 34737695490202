
import React from "react"
import { Modal } from "react-bootstrap"
import AvatarEditor from 'react-avatar-editor'
import "./avatar-cropper.css"

class AvatarCropper extends React.Component {
  constructor () {
    super();

    this.state = {
      scale: 1,
      rotate: 0,
      borderRadius: 125
    }
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  getCroppedImage() {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas();
      return canvas.toDataURL();
    }
    return null;
  }

  render () {
    return (
      <Modal
        title="Crop It"
        onHide={this.props.onRequestHide}
        show={this.props.cropperOpen}
        backdrop={true}>
        <div className="modal-body">
          <div className="AvatarCropper-base">
            <AvatarEditor
              ref={(editor) => this.editor = editor}
              image={this.props.image}
              scale={parseFloat(this.state.scale)}
              borderRadius={this.state.borderRadius}
              width={this.props.width}
              height={this.props.height}
              border={5}
              color={[0, 0, 0, 0.6]} // RGBA
              rotate={0}
            />

            <br />
            Zoom:
            <br />
            <input
              name='scale'
              type='range'
              onChange={(v) => this.handleScale(v)}
              min='1'
              max='3'
              step='0.01'
              defaultValue='1'
            />
            <br />
            <button style={{width: '70px', marginTop: '10px', marginRight: '5px'}} type="button"
              onClick={this.props.onRequestHide} className="btn btn-default" >
                Cancel
            </button>
            <button style={{width: '70px', marginTop: '10px', marginLeft: '5px'}} type="button"
              onClick={() => {const img = this.getCroppedImage(); this.props.onCrop(img)}}
              className="btn btn-primary" >
                Crop
            </button>


          </div>

        </div>
      </Modal>
    );
  }
}

AvatarCropper.defaultProps = {modalSize: "large"};

export default AvatarCropper;
