import React from "react"
import { Button } from "react-bootstrap";
import { ModalContext } from "components/modal/ModalContext";
import "./dialog.css"

const Dialog = ( props: any ): JSX.Element => {
  const { hideModal, isFadingOut } = React.useContext(ModalContext);
  return (
    <div className="modal-background">
      {
        !isFadingOut && <div className="dialog">
          <div className="label">{props.label}</div>
          <div className="buttons">
            <Button variant="outline-primary" onClick={()=>hideModal('no')}>No</Button>
            <Button variant="primary" onClick={()=>hideModal('yes')}>Yes</Button>
          </div>
        </div>
      }
    </div>
  )
}

export default Dialog