import React from 'react'
import { ModalContext } from "components/modal/ModalContext"
import "./modal.css"

const Modal = ():JSX.Element => {

  const { isModalOn, isFadingOut, content } = React.useContext(ModalContext);

  return (
    <>
      {
        isModalOn && 
        <>
          <div className={`body_modal ${isFadingOut? 'fade' : 'show'}`} />
          {
            content
          }
        </>
      }
    </>
  )
} 

export default Modal
