import pack from "../../package.json"

const Copyright = (): JSX.Element => {
  return (
    <div className="container">
      <div style={{paddingTop: '10px', textAlign: 'center', fontSize: '10pt', color: '#959fa5'}}>
        MyInfra Backoffice v{pack.version}<br/>©2017 Datacentric
      </div>
    </div>
  )
}

export default Copyright