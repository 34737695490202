import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { Role } from "pages/users"

// Define a type for the slice state
export interface ContextState {
  role?: Role
}

// Define the initial state using that type
const initialState: ContextState = {
  role: "public"
}

export const contextSlice = createSlice({
  name: "context",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<ContextState>) => {
      Object.assign(state, action.payload)
    }
  },
})

export const { setRole } = contextSlice.actions

export default contextSlice.reducer