import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import userReducer from "redux/slices/user"
import contextReducer from "redux/slices/context"

export const store = configureStore({
  reducer: {
    user: userReducer,
    context: contextReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>