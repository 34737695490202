import { Breadcrumb } from "react-bootstrap"
import { useAppSelector } from "redux/hooks"
import { Link as RouterLink, useLocation } from "react-router-dom"

const Breadcrumbs: React.FC = (): JSX.Element => {

  const isAuthenticated = useAppSelector((state: any) => state.user.isAuthenticated)
  const location = useLocation()
  const pathnames = location.pathname.split("/").filter((x: string) => x)

  return (
    <Breadcrumb id="breadcrumb"  >
    {
      ["/", ""].indexOf(location.pathname) > -1 || !isAuthenticated ?
      <Breadcrumb.Item key="/" active>MyInfra</Breadcrumb.Item> : 
      <Breadcrumb.Item linkAs={RouterLink} linkProps={{ to: "/" }}>MyInfra</Breadcrumb.Item>
    }
    
    {

      pathnames.map((value: string, index: number) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join("/")}`

        return last ? 
        <Breadcrumb.Item key={to} active>{value}</Breadcrumb.Item>
        : 
        <Breadcrumb.Item key={to} linkAs={RouterLink} linkProps={{ to: to}}>{value}</Breadcrumb.Item>
      })

    }
    </Breadcrumb>
  )
}

export default Breadcrumbs