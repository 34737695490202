import { Component } from 'react'
import "./avatar-upload.css"

export default class AvatarUpload extends Component {

  handleFile(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    reader.onload = (img) => {
      this.props.handleFileChange(img.target.result);
    }

    reader.readAsDataURL(file);
  }

  render() {
    return (
      <div style={{width: '200px', height: '200px', margin: 'auto'}}>
      <label htmlFor={"avatar-file"}>

        <div id="avatar">

            <img alt="avatar" src={this.props.croppedImg} />

            <div className="avatar-edit">
              <i className="fa fa-cloud-upload"></i>
            </div>

        </div>

      </label>
      <input style={{visibility: 'hidden', height: '0px'}} id="avatar-file" type="file" accept="image/*" onChange={(e) => this.handleFile(e)} />
      </div>
  );
  }

}
