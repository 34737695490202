import { useCallback } from "react"
import { FieldKey, SortBy, SortingOrder, Column} from "pages/users/Users"
import "./CustomTableHead.css"

interface CustomTableHeadProps {
  columns: Column [],
  currentSortBy: SortBy,
  setSortBy: (updatedSortBy: SortBy) => void
}

const CustomTableHead = (props: CustomTableHeadProps): JSX.Element => {
 
  const {columns, currentSortBy, setSortBy} = props

  const getSortOrderForField = useCallback((id: string): SortingOrder | "none" => {
    if (currentSortBy.field === id) {
      return currentSortBy.order
    }
    return "none"
  }, [currentSortBy])

  const createProps = useCallback((col: Column) => {

    if (!col.sortable)
      return {
        className: "nonsortable"
      }

    const newSortBy: SortBy = {
      field: col.id as FieldKey,
      order: "ascending"
    }

    if (col.id === currentSortBy.field) {
      newSortBy.order = currentSortBy.order === "ascending" ? "descending" : "ascending"
    }

    return {
      className: "sortable",
      onClick: () => {
        setSortBy(newSortBy)
      }
    }
  }, [currentSortBy, setSortBy])
  

  const headers = columns.map((col, idx) => {

    const order: SortingOrder | "none" = getSortOrderForField(col.id)
    const props = createProps(col)

    return (
      <th key={idx} {...props}>
        <span>
          {col.label}
        </span>
        {
          col.sortable && order && (
            <span className={`sort-icon sort-${order}`} aria-hidden="true" />
          )
        }
      </th>
    )
  })

  
  return (
    <thead className="custom-thead">
      <tr>
        {headers}
      </tr>
    </thead>
  )
}

export default CustomTableHead