import "./lms.css"

const Lms = (): JSX.Element => {

    return (
      <div className="container lms">
        <iframe 
          title="lms" 
          frameBorder="0" 
          scrolling="no" 
          seamless={true}
          src="https://myinfra.yale-nus.edu.sg/lms/?embed=true" 
          height="1280px" 
          width="100%" />
      </div>
    )

}

export default Lms
