import Copyright from "components/Copyright"
import "./login.css"

const Login = (props: any): JSX.Element =>  {

    return (

        <div className="login-container">
          {/*
              <a className="btn btn-block btn-social btn-nus" href="https://myinfra.yale-nus.edu.sg/login">
                <span className="fa fa-user"></span> Sign in with NUS
              </a>
          */}

          <a className="btn btn-block btn-social btn-nus" href="/login">
            <span className="fa fa-user"></span> Sign in with ADFS
          </a>

          <a className="btn btn-block btn-social btn-google" href="/login/google">
            <span className="fa fa-google"></span> Sign in with Google
          </a>
          
          <a className="btn btn-block btn-social btn-facebook" href="/login/facebook">
            <span className="fa fa-facebook"></span> Sign in with Facebook
          </a>

          <hr />

          <Copyright />

        </div>

    )
  }

  export default Login