import React from "react"
import { ReactComponent as Gear } from "assets/images/gear.svg"
import { ReactComponent as Trash } from "assets/images/trash.svg"
import Dialog from "components/modal/Dialog";
import { ModalContext } from "components/modal/ModalContext";
import { useNavigate } from "react-router-dom"

interface CustomActionCellProps {
  id: string
}

const CustomActionCell = (props: CustomActionCellProps): JSX.Element => {

  const { showModal } = React.useContext(ModalContext);
  const navigate = useNavigate() 
  const {id} = props

  return (
    <div style={{float: 'right'}}>

      <Gear style={{cursor: 'pointer'}} 
        height={24} 
        width={24} 
        fill="#337ab7" 
        onClick={() => {
          navigate(`/users/${id}`)
        }}
      />

      {" "}

      <Trash style={{cursor: 'pointer'}} 
        height={24} 
        width={24} 
        fill="#337ab7" 
        onClick={async () => {

          const res = await showModal(
            <Dialog label="Are you sure you want to delete this user?"/>
          )

          if (res === 'yes') {
            //apiClient.delete(`/api/users/${id}`)
          }

        }}
      />
    </div>
  )
}

export default CustomActionCell