import React, { useState, useEffect, useCallback } from "react"
import { useAppSelector, useAppDispatch } from "redux/hooks"
import { setRole } from "redux/slices/context"
import { ApiClient, useFetch } from "hooks/useFetch"

import Table from 'react-bootstrap/Table';
import { Button, ButtonGroup } from "react-bootstrap";

import Copyright from "components/Copyright";
import CustomPagination from "components/table/CustomPagination"
import CustomTableBody from "components/table/CustomTableBody"
import CustomTableHead from "components/table/CustomTableHead"
import Login from "pages/login";

import "./users.css"


export type Role = "public" | "admin" | "officer"
export type SortingOrder = "ascending" | "descending"

export interface Column {
  id: string, 
  label: string,
  sortable: boolean
}

export type FieldKey = keyof UserData;

export interface SortBy {
  field: FieldKey,
  order: SortingOrder
}

const defaultSortBy: SortBy = {
  field: "displayName",
  order: "ascending"
}

interface UserProps {
  role?: Role
}

export type UserData = {
  id: string
  displayName: string
  email: string
  role: Role
}


const Users = (props: UserProps): JSX.Element => {

  const dispatch = useAppDispatch()
  const role = useAppSelector((state: any) => state.context.role)

  const columns: Column[] = [
    {id:"#", label: "#", sortable: false}, 
    {id:"displayName", label: "Display Name", sortable: true}, 
    {id:"email", label: "Email", sortable: true}, 
    {id:"role", label: "Role", sortable: false}, 
    {id:"actions", label: "Actions", sortable: false}, 
  ]

  const isAuthenticated = useAppSelector((state: any) => state.user.isAuthenticated)
  const {data, isLoading: isFetching, get}: ApiClient = useFetch()
  const [rows, setRows] = useState<UserData []>()

  //const [role, setRole]= useState<Role>("public")
  const [isLoading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize] = useState(10)
  const [searchText, setSearchText] = useState("")
  const [sortBy, setSortBy] = useState<SortBy>(defaultSortBy)

  const sort = useCallback((tmp: UserData []) => {
    
    tmp?.sort((a: UserData, b: UserData) => {
        const i = sortBy.order === "ascending" ? -1 : 1

        if ((a[sortBy.field] || "") > (b[sortBy.field] || "")) {
          return -1 * i;
        }
        if ((b[sortBy.field] || "") > (a[sortBy.field] || "")) {
          return 1 * i;
        }
        return 0;
    })

  }, [sortBy])

  const sortAndSet = useCallback((tmp: UserData []) => {
    sort(tmp)
    setRows(tmp)
  }, [sort])

  useEffect(() => {
    const tmp: [UserData] = data && data.map((x: any) => { return {id: x._id, displayName: x.displayName, email: x.email, role: x.role}})
    data?.map((itm: any) => itm.id = itm._id)

    sortAndSet(tmp)
  }, [data, sortAndSet])

  useEffect(() => {
    get(`api/users?${role}`)
  }, [role, get])

  useEffect(() => {
    if (!isFetching)
      setTimeout(()=>setLoading(false), 0)
    else
      setLoading(true)
  }, [isFetching])

  const onPageChange = (page: number) => {
    setPageNumber(page)
  }

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    
    const txt = e.target.value.toLowerCase().trim()

    const result = data.filter( (itm: UserData) => {
      return itm?.displayName?.toLowerCase().indexOf(txt) >= 0 || 
        itm?.email?.toLowerCase().indexOf(txt) >= 0
    })

    setSearchText(txt)
    sortAndSet(result)

  }, [data, sortAndSet])


  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <div className="users">

      <div className="filterbar">

        <input
          id="myinfra-search"
          placeholder="Search by Display Name or Email"
          value={searchText} 
          onChange={handleChange}
        />

        <ButtonGroup>
          <Button variant={(role === "public" ? 'primary' : 'outline-primary')} onClick={() => {
            dispatch(setRole({role: "public"}))
            setPageNumber(1)
            setSearchText("")
          }}>Public</Button>
          <Button variant={ (role === "officer" ? 'primary' : 'outline-primary')} onClick={() => {
            dispatch(setRole({role: "officer"}))
            setPageNumber(1)
            setSearchText("")
          }}>Officer</Button>
          <Button variant={ (role === "admin" ? 'primary' : 'outline-primary')} onClick={() => {
            dispatch(setRole({role: "admin"}))
            setPageNumber(1)
            setSearchText("")
          }}>Admin</Button>
        </ButtonGroup>

      </div>

      <Table className="users-table" hover >

        <CustomTableHead 
          columns={columns}
          currentSortBy={sortBy} 
          setSortBy={setSortBy} 
        />
          
        <CustomTableBody 
          rows={rows} isLoading={isFetching} 
          pageNumber={pageNumber} pageSize={pageSize}
        >

          <tr title="nodata">
            <td colSpan={5} className="text-center">
              No data
            </td>
          </tr>

        </CustomTableBody>
        
      </Table>

      {
        !isLoading && <CustomPagination 
        current={pageNumber} 
        recordCount={rows?.length || 0} 
        pageSize={pageSize}
        pageWindow={5}
        onPageChange={onPageChange}/>
      }

      <Copyright />      
    </div>

  )

}

export default Users