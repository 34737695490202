import React, { useState, useEffect, useCallback } from "react"
import { useNavigate, useParams} from "react-router-dom"
import { ApiClient, useFetch } from "hooks/useFetch"

import { Alert, Button } from "react-bootstrap"
import ReactTags, { MultiValue } from "react-select"

import AvatarCropper from "components/avatar"
import AvatarUpload from "components/avatar/avatar-upload"
import SkeletonWrapper from "components/SkeletonWrapper"
import Spin from "components/Spin"

import "./user.css"
import defaultAvatar from 'assets/images/avatar.png'


interface Tag {
  value: string, 
  label: string
}

interface Icon {
  name: string,
  b64: string
}

interface Payload {
  displayName: string,
  phone: string,
  email: string,
  role: string,
  provider: string,
  department: string,
  skills: string[],
  icon?: Icon | null
}

const initialPayload: Payload = {
  displayName: "",
  phone: "",
  email: "",
  role: "Officer",
  provider: "",
  department: "",
  skills: []
}

const initialSuggestions: Tag[] = [
  { value: "pests", label: "Pests" },
  { value: "cardAccess", label: "Door" },
  { value: "aircon", label: "Aircon" },
  { value: "power", label: "Power Trip" },
  { value: "lighting", label: "Lighting" },
  { value: "plumbing", label: "Plumbing" },
  { value: "building", label: "Building" },
  { value: "electricalAppliances", label: "Electrical Appliances" },
  { value: "others", label: "Others" }
]

const dataURItoBlob = (dataURI: string) => {
  const data = dataURI.split(',')[1]
  var binary = atob(data.replace(/_/g, '/').replace(/-/g, '+'))
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const User = (props: any): JSX.Element => {
  let { id } = useParams()
  const navigate = useNavigate() 

  const {data: readerData, get: readData, error: readerError}: ApiClient = useFetch()
  const {data: avatarData, get: getAvatar}: ApiClient = useFetch()
  const writer: ApiClient = useFetch()


  const [isCropperOpen, setCropperOpen] = useState(false)
  const [isFileUpdated, setFileUpdated] = useState(false)
  const [image, setImage] = useState(defaultAvatar)
  const [isLoading, setLoading] = useState(true)

  const [croppedImage, setCroppedImage] = useState(defaultAvatar) 

  const [payload, setPayload] = useState(initialPayload)
  const {displayName, provider, email, phone, role, department} = payload;
  
  const [suggestions, setSuggestions] = useState<Tag[]>(initialSuggestions)
  const [skills, setSkills] = useState<Tag[]>([])

  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSucessMessage] = useState("")
  const [minimalDelayReached, setMinimalDelayReached] = useState(false)

  useEffect(() => {
    readData(`/api/users/${id}`)
    getAvatar(`/api/users/${id}/avatar`, {responseType: 'blob'})
    setTimeout(() => setMinimalDelayReached(true), 300)
  }, [readData, getAvatar, id ])

  useEffect(() => {
    if (avatarData && readerData && minimalDelayReached){
      setCroppedImage(URL.createObjectURL(avatarData))
      setPayload ({...initialPayload, ...readerData})

      const _suggestions = initialSuggestions.filter( 
        itm => readerData.skills.find((skill: string) => skill === itm.value) === undefined )

      const _skills = initialSuggestions.filter( 
        itm => readerData.skills.find((skill: string) => skill === itm.value) )
  
      setSkills(_skills as Tag[])
      setSuggestions(_suggestions)
      
      setLoading(false)
    }
  }, [avatarData, readerData, minimalDelayReached])

  useEffect(() => {
      setErrorMessage(readerError)
  }, [readerError])



  const handleFileChange = useCallback((dataURI: string) => {
    setImage(dataURI)
    setCropperOpen(true)
  }, [])

  const handleCrop = useCallback((dataURI: string) => {
    setImage(defaultAvatar)
    setCropperOpen(false)
    setFileUpdated(true)
    setCroppedImage(dataURI)
  }, [])

  const handleRequestHide = useCallback(() => {
    setCropperOpen(false)
  }, [])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
    const _payload  = {...payload, [name]: e.target.value}
    setPayload(_payload)
  }, [payload])
  
  const onSkillChange = useCallback((_skills: MultiValue<Tag>) => {
    const _suggestions = initialSuggestions.filter( 
      itm => _skills.find(skill => skill.value === itm.value) === undefined )

    setSkills(_skills as Tag[])
    setSuggestions(_suggestions)
  }, [])

  const onSubmit = useCallback (async () => {
    const data = {...payload}
    data.skills = skills.map(itm => itm.value)
    data.role = data.role || 'Officer';

    const frmData = new FormData();

    // if the file is new or updated store the avatar
    if (isFileUpdated){
      frmData.append("avatar", dataURItoBlob(croppedImage), "avatar.png");
    }
    
    frmData.append("payload", JSON.stringify(data));

    const status = await writer.put(`/api/users/${id}`, frmData)

    if (status === 205) {
      setSucessMessage("The profile has been updated successfully.")
    } 
    else {
      setErrorMessage("Unable to submit requested data.")
    }

  }, [id, payload, isFileUpdated, skills, writer, croppedImage])

  return (
    <div className="container">
    {
      <>
      <div style={{height: '200px'}}>

        <SkeletonWrapper 
          isLoading={isLoading}
          variant="circle"
          containerClassName="avatar-skeleton"
          width="200px"
          height="200px"
          style={{
            top: '0px',
            width: '200px',
            height: '200px',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'}}
        >
          <AvatarUpload croppedImg={croppedImage} handleFileChange={handleFileChange} />
        </SkeletonWrapper>
        
        {
          isCropperOpen &&
          <AvatarCropper
            onRequestHide={handleRequestHide}
            cropperOpen={isCropperOpen}
            onCrop={handleCrop}
            image={image}
            width={255}
            height={255}
          />
        }
      </div>

      <div className="row" style={{padding: '10px', opacity: successMessage ? 1: 0, transition: 'opacity 3s ease'}}>
            {
              successMessage &&
              <div className="form-group offset-md-2 col-md-8" >
                  <button style={{width: '70px', margin: '10px', float: 'right', position: 'relative', zIndex: 2}} type="button"
                  onClick={() => setSucessMessage("")}

                  className="btn btn-success-outline" >
                    OK
                </button>
                <Alert variant="success" style={{zIndex: 1}}>
                  <strong>Success! </strong>{successMessage}
                </Alert>
                
              </div>
            }
      </div>

      <div className="row" style={{padding: '10px', opacity: errorMessage ? 1: 0, transition: 'opacity 3s ease'}}>
            {
              errorMessage &&
              <div className="form-group offset-md-2 col-md-8" >
                <button style={{width: '70px', margin: '10px', float: 'right', position: 'relative', zIndex: 2}} type="button"
                  onClick={() => setErrorMessage("")}
                  className="btn btn-danger-outline" >
                    OK
                </button>
                <Alert variant="danger" style={{zIndex: 1}}>
                  <strong>Error! </strong>{errorMessage}
                </Alert>


              </div>
            }
      </div>

      <div className="row" style={{height: '86px', padding: '10px'}}>

        <div className="form-group  offset-md-2 col-md-4 ">
          <label htmlFor="displayName">Display name</label>
          <SkeletonWrapper isLoading={isLoading}>
            <input  
              type="text" value={displayName} onChange={(e) => handleChange(e, "displayName")}
              className="form-control" placeholder="Display name" />
          </SkeletonWrapper>
        </div>

        <div className="form-group col-md-4 ">
          <label htmlFor="provider">Provider</label>
          <SkeletonWrapper isLoading={isLoading}>
            <input type="text" value={provider} onChange={(e) => handleChange(e, "provider")}
                className="form-control" placeholder="Provider" />
          </SkeletonWrapper>
        </div>

      </div>

      <div className="row" style={{height: '86px', padding: '10px'}}>

        <div className="form-group  offset-md-2 col-md-4 ">
          <label htmlFor="email">Email</label>
            <SkeletonWrapper isLoading={isLoading}>
              <input type="text" value={email} onChange={(e) => handleChange(e, "email")}
                className="form-control" placeholder="Email" />
            </SkeletonWrapper>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="phone">Phone</label>
          <SkeletonWrapper isLoading={isLoading}>
            <input type="text" value={phone} onChange={(e) => handleChange(e, "phone")}
                className="form-control" placeholder="Phone" />
          </SkeletonWrapper>
        </div>

      </div>

      <div className="row" style={{height: '86px', padding: '10px'}}>
        <div className="form-group  offset-md-2 col-md-4 ">
          <label htmlFor="role">Role</label>
          <SkeletonWrapper isLoading={isLoading}>
            <select value={role} onChange={(e) => handleChange(e, "role")}
              className="form-control">
              <option value="Public">Public</option>
              <option value="Officer">Officer</option>
              <option value="Admin">Admin</option>
            </select>
          </SkeletonWrapper>
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="department">Department</label>
          <SkeletonWrapper isLoading={isLoading}>
            <input type="text" value={department} onChange={(e) => handleChange(e, "department")}
                className="form-control" placeholder="Department" />
          </SkeletonWrapper>
        </div>
      </div>
  
      <div className="row" style={{height: '86px', padding: '10px'}}>
            <div className="form-group offset-md-2 col-md-8">
              <label>Skills</label>
              <SkeletonWrapper isLoading={isLoading}>
                <ReactTags
                  className="form-tags"
                  closeMenuOnSelect={false}
                  isMulti={true}
                  options={suggestions}
                  onChange={onSkillChange}
                  value={skills}
                  placeholder={"Add new skill"}
                />
              </SkeletonWrapper>
            </div>
      </div>

      <div className="form-group" style={{height: '86px', padding: '10px'}}>
        <div className="offset-md-2 col-md-8 text-center">
          <SkeletonWrapper width="300px" height="48px" isLoading={isLoading || !avatarData}>
            <>
              <Button style={{margin: '10px', pointerEvents: 'auto'}} variant="outline-primary"
                onClick={async () => {
                  navigate('/users')
                }}>Back to users
              </Button>

              {
                writer.isLoading ? 
                <Button variant="primary" style={{width: '70px', margin: '10px'}}>
                  <Spin style={{color: "#fff", fontSize: '15px'}}/>
                </Button>
                :
                <Button variant="primary"
                  style={{width: '70px', margin: '10px'}} type="submit" onClick={onSubmit}
                  className="btn btn-primary" >Save
                </Button>
              }

            </>
          </SkeletonWrapper>
        </div>
      </div>
      </>
    }
    </div>
  )
}

export default User
